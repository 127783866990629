import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="row">
        <div className="col-xs-12 col-sm-6 col-sm-offset-3 col-lg-4 col-lg-offset-4 error">            
            <h1>404: Page not found</h1>            
            <p>
               You may have mistyped the address or the page may have moved.
            </p>
            <a href="/" className="btn btn-huge btn-tab">Back to start</a>
        </div>
    </div>
  </Layout>
)

export default NotFoundPage
